import {
  deleteAIConfig,
  deleteAIConfigVersion,
  getAIConfig,
  getAIConfigs,
  internalGetAIConfig,
  patchAIConfig,
  patchAIConfigVersion,
  postAIConfig,
  postAIConfigVersion,
} from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

// fetch a list of AI Configs for dashboard
export const getAiConfigsQuery = createQueryOptions(getAIConfigs);
// fetch all variations of a single AI Config for detail page. Render models and prompts
export const getAiConfigQuery = createQueryOptions(getAIConfig);
// internal query to fetch shadow flag id
export const getAiConfigInternalQuery = createQueryOptions(internalGetAIConfig);

// create an AI Config
export const useCreateAiConfig = createMutationHook(postAIConfig);
// create a new variation (modal & prompt) in an AI Config.
export const usePostVariationAiConfig = createMutationHook(postAIConfigVersion);

// update the selected variation (modal & prompt) in an AI Config. Fetch latest variation afer update
export const useUpdateAiConfigVariation = createMutationHook(patchAIConfigVersion);

// update an AI Config. Fetch latest version afer update
export const useUpdateAiConfig = createMutationHook(patchAIConfig, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey() });
  },
});

// delete all ai config variations. Basically delete everything. Refetch a list of AI configs
export const useDeleteAiConfig = createMutationHook(deleteAIConfig, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.refetchQueries({ queryKey: getAiConfigsQuery.partialQueryKey() });
  },
});

// delete a single ai config variation (model & prompt). Retain any existing version (model & prompts). Refetch vall there AI Config variations
export const useDeleteAiConfigVariation = createMutationHook(deleteAIConfigVersion, {
  onSuccess: async (data, { configKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey({ configKey }) });
  },
});
